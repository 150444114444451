import type { RefreshTokenResponse } from '@/services/auth';
import { AuthService } from '@/services/auth';
import { getAccessToken, getRefreshToken, logout, parseJwt } from '@/utils/authority';
import { handleError } from '@/utils/utils';

const JWT_EXPIRY_THRESHOLD = 30000;
let refreshPromise: Promise<RefreshTokenResponse> | undefined;
let lastAutoRefresh: number | undefined;

const isJwtGood = (jwt: string | null) => {
  try {
    if (!jwt) {
      return false;
    }
    const parsedJwt = parseJwt(jwt);
    if (!parsedJwt) {
      return false;
    }
    return Date.now() < parsedJwt.exp * 1000 - JWT_EXPIRY_THRESHOLD;
  } catch (error) {
    handleError(error, { displayToast: false });
    return false;
  }
};

const getJwtReminderTime = (jwt: string) => {
  const parsedJwt = parseJwt(jwt);
  return parsedJwt!.exp * 1000 - JWT_EXPIRY_THRESHOLD - Date.now();
};

const doRefreshToken = () => {
  const refreshToken = getRefreshToken();
  if (!isJwtGood(refreshToken)) {
    logout();
    return Promise.reject('The access and refresh tokens are both missing or expired');
  }

  refreshPromise =
    refreshPromise ||
    AuthService.refreshToken({ refreshToken: refreshToken! })
      .catch(() => {
        return new Promise((resolve) => {
          setTimeout(() => resolve(undefined), 5000);
        }).then(() => {
          return AuthService.refreshToken({ refreshToken: refreshToken! });
        });
      })
      .finally(() => {
        refreshPromise = undefined;
      });

  return refreshPromise;
};

const handler = (ev: StorageEvent) => {
  if (ev.key === 'access_token') {
    if (lastAutoRefresh) {
      window.clearTimeout(lastAutoRefresh);
    }
    const accessToken = getAccessToken();
    if (!accessToken) {
      return;
    }
    lastAutoRefresh = window.setTimeout(() => {
      lastAutoRefresh = undefined;
      doRefreshToken();
    }, getJwtReminderTime(accessToken));
  }
};

window.addEventListener('storage', handler);

export const getValidAccessToken = () => {
  const accessToken = getAccessToken();
  if (!isJwtGood(accessToken)) {
    return doRefreshToken()
      .then((response) => response.accessToken)
      .catch((error) => {
        handleError(error, { displayToast: false });
        logout();
        return Promise.reject('Error communicating with the authorization system.');
      });
  }

  if (!lastAutoRefresh) {
    lastAutoRefresh = window.setTimeout(() => {
      lastAutoRefresh = undefined;
      doRefreshToken();
    }, getJwtReminderTime(accessToken!));
  }

  return Promise.resolve(accessToken);
};
