import type { Enum } from '@/domain/base';
import type { Duration } from 'moment';
import moment from 'moment';

export const SHORT_TIME_FORMAT = 'h:mm A';
export const SHORT_DATE_FORMAT = 'MM/DD/YYYY';
export const SHORT_DATE_TIME_FORMAT = `${SHORT_DATE_FORMAT} ${SHORT_TIME_FORMAT}`;
export const SHORT_DATE_TIME_FORMAT_WITH_TIMEZONE = `${SHORT_DATE_FORMAT} ${SHORT_TIME_FORMAT} (z)`;
export const DATE_TIME_FORMAT = `${SHORT_DATE_FORMAT} [at] ${SHORT_TIME_FORMAT}`;
export const ISO_DATE_FORMAT = 'YYYY-MM-DD';
export const MONTH_DAY_FORMAT = 'MM/DD';
export const FULL_TIME_FORMAT = 'HH:mm:ss';
export const LOCAL_DATE_TIME_FORMAT = `${ISO_DATE_FORMAT}T${FULL_TIME_FORMAT}`;
export const LOCAL_DATE_TIME_Z_FORMAT = `${ISO_DATE_FORMAT}T${FULL_TIME_FORMAT}\\Z`;
export const MONTH_YEAR_DATE_FORMAT = 'MM/YYYY';

export const timezones: Enum[] = [
  {
    displayName: 'America/Adak',
    value: 'America/Adak',
  },
  {
    displayName: 'America/Anchorage',
    value: 'America/Anchorage',
  },
  {
    displayName: 'America/Chicago',
    value: 'America/Chicago',
  },
  {
    displayName: 'America/Denver',
    value: 'America/Denver',
  },
  {
    displayName: 'America/Los_Angeles',
    value: 'America/Los_Angeles',
  },
  {
    displayName: 'America/New_York',
    value: 'America/New_York',
  },
  {
    displayName: 'America/Phoenix',
    value: 'America/Phoenix',
  },
  {
    displayName: 'America/Puerto_Rico',
    value: 'America/Puerto_Rico',
  },
  {
    displayName: 'America/Virgin',
    value: 'America/Virgin',
  },
  {
    displayName: 'Pacific/Honolulu',
    value: 'Pacific/Honolulu',
  },
  {
    displayName: 'Pacific/Saipan',
    value: 'Pacific/Saipan',
  },
  {
    displayName: 'US/Alaska',
    value: 'US/Alaska',
  },
  {
    displayName: 'US/Aleutian',
    value: 'US/Aleutian',
  },
  {
    displayName: 'US/Arizona',
    value: 'US/Arizona',
  },
  {
    displayName: 'US/Central',
    value: 'US/Central',
  },
  {
    displayName: 'US/East-Indiana',
    value: 'US/East-Indiana',
  },
  {
    displayName: 'US/Eastern',
    value: 'US/Eastern',
  },
  {
    displayName: 'US/Hawaii',
    value: 'US/Hawaii',
  },
  {
    displayName: 'US/Indiana-Starke',
    value: 'US/Indiana-Starke',
  },
  {
    displayName: 'US/Michigan',
    value: 'US/Michigan',
  },
  {
    displayName: 'US/Mountain',
    value: 'US/Mountain',
  },
  {
    displayName: 'US/Pacific',
    value: 'US/Pacific',
  },
  {
    displayName: 'US/Pacific-New',
    value: 'US/Pacific-New',
  },
  {
    displayName: 'US/Samoa',
    value: 'US/Samoa',
  },
];

export type DayOfWeek =
  | 'MONDAY'
  | 'TUESDAY'
  | 'WEDNESDAY'
  | 'THURSDAY'
  | 'FRIDAY'
  | 'SATURDAY'
  | 'SUNDAY';

export const days: { value: DayOfWeek; label: string }[] = [
  {
    value: 'MONDAY',
    label: 'Monday',
  },
  {
    value: 'TUESDAY',
    label: 'Tuesday',
  },
  {
    value: 'WEDNESDAY',
    label: 'Wednesday',
  },
  {
    value: 'THURSDAY',
    label: 'Thursday',
  },
  {
    value: 'FRIDAY',
    label: 'Friday',
  },
  {
    value: 'SATURDAY',
    label: 'Saturday',
  },
  {
    value: 'SUNDAY',
    label: 'Sunday',
  },
];

export const formatSeconds = (seconds: number) => {
  const sec = Math.floor(seconds % 60);
  const min = Math.floor(seconds / 60);
  return `${min}:${sec.toString().padStart(2, '0')}`;
};

export enum DurationGranularity {
  YEARS = 6,
  MONTHS = 5,
  DAYS = 4,
  HOURS = 3,
  MINUTES = 2,
  SECONDS = 1,
}

interface DurationFormatSettings {
  granularity?: DurationGranularity;
  unitMapping?: Record<DurationGranularity, string>;
}

const SHORT_DURATION_UNITS_MAPPING: Record<DurationGranularity, string> = {
  [DurationGranularity.YEARS]: 'yr',
  [DurationGranularity.MONTHS]: 'mo',
  [DurationGranularity.DAYS]: 'd',
  [DurationGranularity.HOURS]: 'h',
  [DurationGranularity.MINUTES]: 'm',
  [DurationGranularity.SECONDS]: 's',
};

export const formatDuration = (duration: Duration, settings?: DurationFormatSettings): string => {
  const granularity = settings?.granularity || DurationGranularity.DAYS;
  const unitMapping = settings?.unitMapping || SHORT_DURATION_UNITS_MAPPING;

  const years =
    duration.years() > 0 ? `${duration.years()} ${unitMapping[DurationGranularity.YEARS]} ` : '';
  const months =
    granularity <= DurationGranularity.MONTHS && (duration.years() > 0 || duration.months() > 0)
      ? `${duration.months()} ${unitMapping[DurationGranularity.MONTHS]} `
      : '';
  const day =
    granularity <= DurationGranularity.DAYS &&
    (duration.years() > 0 || duration.months() > 0 || duration.days() > 0)
      ? `${duration.days()} ${unitMapping[DurationGranularity.DAYS]} `
      : '';
  const hours =
    granularity <= DurationGranularity.HOURS &&
    (duration.years() > 0 || duration.months() > 0 || duration.days() > 0 || duration.hours() > 0)
      ? `${duration.hours()} ${unitMapping[DurationGranularity.HOURS]} `
      : '';
  const minutes =
    granularity <= DurationGranularity.MINUTES &&
    (duration.years() > 0 ||
      duration.months() > 0 ||
      duration.days() > 0 ||
      duration.hours() > 0 ||
      duration.minutes() > 0)
      ? `${duration.minutes()} ${unitMapping[DurationGranularity.MINUTES]} `
      : '';

  const seconds =
    granularity <= DurationGranularity.SECONDS &&
    (duration.years() > 0 ||
      duration.months() > 0 ||
      duration.days() > 0 ||
      duration.hours() > 0 ||
      duration.minutes() > 0 ||
      duration.seconds() > 0)
      ? `${duration.seconds()} ${unitMapping[DurationGranularity.SECONDS]}`
      : '';

  return `${years}${months}${day}${hours}${minutes}${seconds}`;
};

export const formatAge = (date: string) => {
  const duration = moment.duration(moment().diff(date));
  return formatDuration(duration);
};

export const validateDates = ({
  value,
  before,
  after,
}: {
  value: string | undefined | null;
  before: string[];
  after: string[];
}): boolean => {
  if (!value) {
    return true;
  }
  const date = moment(value);

  if (before.length > 0 && before.some((d) => !!d && date.isAfter(moment(d)))) {
    return false;
  }

  if (after.length > 0 && after.some((d) => !!d && date.isBefore(moment(d)))) {
    return false;
  }
  return true;
};
