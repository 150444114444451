import type { NumRange } from '@/components/NumberRange';
import type { Address } from '@/domain/address';
import type { Enum } from '@/domain/base';
import type { DateRange } from '@/domain/date-range';
import { EntityType } from '@/domain/entity-type';
import { InvoiceAttributionEntityType } from '@/domain/invoices';
import { JobType } from '@/domain/job';
import type { LeaseSearchResult } from '@/domain/lease';
import { LeaseStatus } from '@/domain/lease';
import { ProcessType } from '@/domain/process';
import { ResidentStatus } from '@/domain/resident';
import type { Task } from '@/domain/tasks';
import type { UserLookup } from '@/domain/user';
import { SentryService } from '@/services/sentry';
import message from 'antd/es/message';
import type Big from 'big.js';
import type { Operation } from 'fast-json-patch';
import { compare } from 'fast-json-patch';
import type { Location } from 'history';
import pick from 'lodash/pick';
import type { Moment } from 'moment';
import moment from 'moment';
import pLimit from 'p-limit';
import queryString from 'query-string';
import { useState } from 'react';
import { SHORT_DATE_FORMAT } from './time';

const DEFAULT_ERROR_MESSAGES_BY_HTTP_STATUS: Record<number, string> = {
  409: 'Information has changed since you first loaded this page. Please refresh the page and retry your action.',
  403: 'Missing permission',
};

export const getErrorMessage = (error: any, defaultMessage?: string) => {
  if (!error) {
    return defaultMessage || '';
  }

  if (typeof error === 'string') {
    return error;
  }

  const msg = error.displayMessage || error.textResponse || error.message;

  if (
    !msg &&
    error?.response?.status &&
    DEFAULT_ERROR_MESSAGES_BY_HTTP_STATUS[error.response.status]
  ) {
    return DEFAULT_ERROR_MESSAGES_BY_HTTP_STATUS[error.response.status];
  }

  if (defaultMessage) {
    return msg || defaultMessage;
  }

  return msg || '';
};

const parseServiceErrorMessage = (msg: string): string => {
  if (typeof msg !== 'string') {
    // eslint-disable-next-line no-console
    console.error('Message must be a string', msg);
    return msg;
  }

  /** @see https://skyboxcapital.atlassian.net/browse/FB-242 */
  if (/^Entity EmailTemplate already exists/.test(msg)) {
    return 'An Email Template with this name already exists';
  }

  return msg;
};

type HandleErrorOptions = {
  displayToast?: boolean;
  /** This is the message displayed even if the error includes an error message */
  toastMessage?: string;
  /** This is a fallback message displayed if the error doesn't include an error message and `toastMessage` is not set */
  toastFallbackMessage?: string;
  rethrowError?: boolean;
  sendToSentry?: boolean;
  parseServiceError?: boolean;
  skipIfPermissionIssue?: boolean;
};

export const handleError = (
  error: any,
  {
    displayToast = true,
    toastMessage,
    toastFallbackMessage = 'Unexpected Error',
    rethrowError,
    sendToSentry = true,
    parseServiceError = false,
    skipIfPermissionIssue = false,
  }: HandleErrorOptions = {},
) => {
  if (skipIfPermissionIssue && error?.status === 403) {
    return;
  }

  if (displayToast) {
    let errorMessage = toastMessage ?? getErrorMessage(error, toastFallbackMessage);

    if (parseServiceError) {
      errorMessage = parseServiceErrorMessage(errorMessage);
    }

    message.error(errorMessage);
  }
  if (sendToSentry) {
    SentryService.trackError(error);
  }

  if (rethrowError) {
    throw error;
  }
};

export const capitalize = (value: string) => value.charAt(0).toUpperCase() + value.slice(1);

export const transformSnakeCaseToCapitalizedWords = (value: string) => {
  if (!value) {
    return '';
  }

  return value
    .trim()
    .split(/[\s_]+/)
    .map((word) => capitalize(word.toLocaleLowerCase()))
    .join(' ');
};

export const enumToArray = <EnumValue>(e: Record<string, EnumValue>): EnumValue[] =>
  Object.values(e);

export const parseToOption = <V = string, T = Record<string, any>>(
  obj: T,
  labelPropName: keyof T = 'displayName' as keyof T,
  valuePropName: keyof T = 'value' as keyof T,
  metaKeys: (keyof T)[] = [] as (keyof T)[],
) => ({
  label: obj[labelPropName] as unknown as string,
  value: obj[valuePropName] as unknown as V,
  ...(metaKeys.length ? { meta: pick(obj, metaKeys) } : {}),
});

export const paramToArray = <T>(value: T | T[] | undefined): T[] | undefined => {
  if (value === undefined) {
    return undefined;
  }

  if (Array.isArray(value)) {
    return value;
  }

  if (typeof value === 'string') {
    return value.split(',').filter((x) => x !== '') as unknown as T[];
  }

  return value ? [value] : undefined;
};

export const enumToOptions = <T = string, R extends Enum<T> = Enum<T>>(enumItems: R[]) =>
  (enumItems || []).map((enumItem) => parseToOption<T>(enumItem));

export const to = <T = any>(promise: Promise<T>) =>
  promise
    .then((result) => [undefined, result])
    .catch((error) => {
      handleError(error, { displayToast: false });
      return [error];
    });

// FIXME: the `AGENT` role doesn't necessarily exist for every organization. This should be replaced with something that
//  uses permissions instead.
export const filterAgents = (users: UserLookup[]) =>
  users.filter((user) => user.roles.some((role) => role.type.name === 'AGENT'));

export const getAddressDescription = (address?: Address, lot?: string) => {
  if (!address || !address.addr1) {
    return '';
  }

  const { addr1, addr2, city, state, postalCode } = address;
  const addr2Text = addr2 ? ` ${addr2}` : '';
  const lotText = lot ? `Lot ${lot} - ` : '';

  return `${lotText}${addr1}${addr2Text}, ${city}, ${state} ${postalCode}`;
};

export const getShortAddressDescription = (address?: Address, lot?: string) => {
  if (!address || !address.addr1) {
    return '';
  }

  const addr2 = address.addr2 ? ` ${address.addr2}` : '';
  const lotText = lot ? `Lot ${lot} - ` : '';

  return `${lotText}${address.addr1}${addr2}`;
};

export const dateRangeToQueryParam = (value: DateRange | undefined, format?: string) => {
  const formatDate = (date: Moment) => (format ? date.format(format) : date.toISOString());

  if (!value) {
    return undefined;
  }

  const left = value[0];
  const right = value[1];

  if (left && right) {
    return `${formatDate(left.startOf('day'))}~${formatDate(right.endOf('day'))}`;
  }

  if (left) {
    return `${formatDate(left.startOf('day'))}~INF`;
  }

  if (right) {
    return `-INF~${formatDate(right.endOf('day'))}`;
  }

  return undefined;
};

export const queryParamToDateRange = (value: string): DateRange => {
  if (!value.includes('~')) {
    return null;
  }

  const fromDate = value.startsWith('-INF') ? null : moment(value.substring(0, value.indexOf('~')));
  const toDate = value.endsWith('INF') ? null : moment(value.substring(value.indexOf('~') + 1));

  return [fromDate, toDate];
};

export const isDateInRange = (date: Moment | undefined, range: DateRange | undefined) => {
  if (!date || !range) {
    return false;
  }

  // Case "-INF~INF"
  if (range[0] === null && range[1] === null) {
    return true;
  }

  // Case "-INF~{some-date}"
  if (range[0] === null && range[1] !== null) {
    return date.isSameOrBefore(moment(range[1]).endOf('day'));
  }

  // Case "{some-date}~INF"
  if (range[0] !== null && range[1] === null) {
    return date.isSameOrAfter(moment(range[0]).startOf('day'));
  }

  return date.isBetween(
    moment(range[0]).startOf('day'),
    moment(range[1]).endOf('day'),
    undefined,
    '[]', // Important
  );
};

export const numberRangeToQueryParam = (value: NumRange) => {
  if (value === undefined) {
    return undefined;
  }

  const hasValue = (x: number | undefined | null) => x !== undefined && x !== null;

  const left = value[0];
  const right = value[1];

  if (hasValue(left) && hasValue(right)) {
    return `${left}~${right}`;
  }

  if (hasValue(left)) {
    return `${left}~INF`;
  }

  if (hasValue(right)) {
    return `-INF~${right}`;
  }

  return undefined;
};

export const queryParamToNumberRange = (value: string): NumRange => {
  const left = value.startsWith('-INF')
    ? undefined
    : parseInt(value.substring(0, value.indexOf('~')), 10);
  const right = value.endsWith('INF')
    ? undefined
    : parseInt(value.substring(value.indexOf('~') + 1), 10);
  return [left, right];
};

export const filterSelectOptions =
  (optionKey: string) => (input: string, option: object | undefined) =>
    (option?.[optionKey] || '').toLowerCase().indexOf(input.toLowerCase()) >= 0;

export const getBooleanOptions = () => [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

export const getFullName = (
  { firstName, lastName }: { firstName?: string; lastName?: string } = {
    firstName: '',
    lastName: '',
  },
) => `${firstName ?? ''} ${lastName ?? ''}`;

export const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' });

export const nameSorter = (a: any, b: any) =>
  collator.compare(getFullName(a) ?? 0, getFullName(b) ?? 0);

export const pluralize = (count: number, noun: string, suffix = 's') =>
  `${count} ${noun}${count !== 1 ? suffix : ''}`;

export const getBathroomCount = ({
  fullBathroomCount = 0,
  halfBathroomCount = 0,
  quarterBathroomCount = 0,
  threeQuarterBathroomCount = 0,
}) =>
  fullBathroomCount +
  threeQuarterBathroomCount +
  0.5 * halfBathroomCount +
  0.25 * quarterBathroomCount;

export const formatPercentage = (value?: string | number | Big, noDecimals?: boolean) => {
  if (value == null) {
    return '';
  }

  const formatted = noDecimals
    ? parseInt(typeof value === 'string' ? value : `${Number(value)}`, 10)
    : Number(value).toFixed(2);
  return `${formatted}%`;
};

export const formatNumber = (value?: string | number | Big, fractionDigits: number = 2) =>
  value == null ? '' : Number(value).toFixed(fractionDigits);

export const formatFromHours = (value: number) => {
  if (!value) {
    return '';
  }

  const pluralSuffix = (amount: number, type: string) => {
    const useType = `${type}${amount !== 1 ? 's' : ''}`;
    return `${amount} ${useType}`;
  };

  let weeks = 0;
  let days = Math.floor(value / 24);
  const hours = value % 24;

  if (days > 6) {
    weeks = Math.floor(days / 7);
    days %= 7;
  }

  const result = [];
  if (weeks) {
    result.push(pluralSuffix(weeks, 'week'));
  }
  if (days) {
    result.push(pluralSuffix(days, 'day'));
  }
  if (hours) {
    result.push(pluralSuffix(hours, 'hour'));
  }

  return result.join(' ');
};

export const formatTemperature = (value?: string | number, type: string = 'F') => {
  if (value === null || value === undefined) {
    return '';
  }

  const parsed = Number(value);

  if (isNaN(parsed)) {
    return '';
  }

  const formatted = parsed.toFixed(0);
  return `${formatted}°${type}`;
};

export const getJsonPatchOperations = <T extends Object>(object: T, updated: T) => {
  return compare(object, updated);
};

export const getOperations = (
  values: Record<string, any>,
  operation: Operation['op'] = 'replace',
) =>
  Object.entries(values)
    .filter(([, value]) => value !== undefined)
    .map(([key, value]) => {
      return {
        op: operation,
        path: `/${key}`,
        value,
      } as Operation;
    });

const isValidNumRangeValue = (value?: number) =>
  value !== undefined && value !== null && !isNaN(value);

export const inRange = (value: number | undefined, range: NumRange): boolean => {
  if (value === undefined) {
    return false;
  }

  const [min, max] =
    !isValidNumRangeValue(range[0]) || !isValidNumRangeValue(range[1])
      ? range
      : [...range].sort((a, b) => {
          return a! - b!;
        });
  const hasMin = typeof min === 'number' && !isNaN(min);
  const hasMax = typeof max === 'number' && !isNaN(max);
  const matchMin = hasMin && value >= min;
  const matchMax = hasMax && value <= max;

  if (!hasMin && !hasMax) {
    return false;
  }

  if (hasMin && hasMax) {
    return !!(matchMin && matchMax);
  }

  if (hasMin) {
    return !!matchMin;
  }

  if (hasMax) {
    return !!matchMax;
  }

  return true;
};

export const inValues = (value: string | undefined, values: string[]) => {
  if (!value || !values.length) {
    return true;
  }
  return values.find((val) => val.toLowerCase() === value.toLowerCase());
};

export const inString = (value: string | undefined, keyword: string) => {
  if (!value || !keyword) {
    return true;
  }
  return value.toLowerCase().includes(keyword.trim().toLowerCase());
};

export const randomInt = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min)) + min;

export type StateFromEventData<T> = Partial<T>;

type PropertyTypes<TObj> = TObj[keyof TObj];

export const displayNAByDefault = <T = any>(
  value: null | undefined | PropertyTypes<T>,
  valueIfNotNull?: (() => any) | any,
) => {
  if (value === null || value === undefined) {
    return 'N/A';
  }

  if (valueIfNotNull === undefined) {
    return value;
  }

  if (typeof valueIfNotNull === 'function') {
    return valueIfNotNull();
  }

  return valueIfNotNull;
};

export const useArrayEdition = <T = number>(initialArray: T[] = []) => {
  const [editList, setEditList] = useState<T[]>(initialArray);
  const [saveList, setSaveList] = useState<T[]>([]);

  const edit = (id: T, value: boolean = true) =>
    setEditList((lst) => (value ? [...lst, id] : lst.filter((n) => n !== id)));

  const isEditing = (id: T) => editList.includes(id);

  const save = (id: T, value: boolean = true) =>
    setSaveList((lst) => (value ? [...lst, id] : lst.filter((n) => n !== id)));

  const isSaving = (id: T) => saveList.includes(id);

  const reset = (id: T) => {
    save(id, false);
    edit(id, false);
  };

  return {
    edit,
    isEditing,
    save,
    isSaving,
    reset,
  };
};

export const downloadArrayBuffer = (content: ArrayBuffer, filename: string, type?: string) => {
  const link = document.createElement('a');
  link.style.display = 'none';
  document.body.appendChild(link);

  const blob = new Blob([content], { type });

  link.href = URL.createObjectURL(blob);
  link.download = filename;
  link.click();
  URL.revokeObjectURL(link.href);
};

export const isSameDay = (dateTime1: string, dateTime2: string): boolean => {
  return moment.utc(dateTime1).isSame(moment.utc(dateTime2), 'day');
};

// NOTE: keep in mind that this ignores the year when comparing
export const areMonthAndDateRangesOverlapping = (
  from1: Moment,
  until1: Moment,
  from2: Moment,
  until2: Moment,
) => {
  let f1 = Number(from1.format('MMDD'));
  let u1 = Number(until1.format('MMDD'));
  let f2 = Number(from2.format('MMDD'));
  let u2 = Number(until2.format('MMDD'));

  if (f1 > u1) {
    u1 += 10000;
  }
  if (f2 > u2) {
    u2 += 10000;
  }

  const isBetween = (n: number, b1: number, b2: number) => b1 <= n && n <= b2;
  const check = () =>
    isBetween(f1, f2, u2) ||
    isBetween(u1, f2, u2) ||
    isBetween(f2, f1, u1) ||
    isBetween(u2, f1, u1);

  if (check()) {
    return true;
  }

  if (u1 > 9999) {
    f2 += 10000;
    u2 += 10000;
  } else if (u2 > 9999) {
    f1 += 10000;
    u1 += 10000;
  }

  return check();
};

/**
 * Use this function instead of 'lodash/chain'
 * @see https://skyboxcapital.atlassian.net/browse/PN-4485
 * @see https://github.com/lodash/lodash/issues/4712
 */
export const groupArrayBy = <T, KeyType = string>(
  array: T[],
  key: keyof T,
  sortFn?: (a: T, b: T) => number,
) => {
  const result = new Map<unknown, T[]>();

  array.forEach((item) => {
    const value = item[key];
    if (!result.has(item[key])) {
      result.set(value, [item]);
    } else {
      result.get(value)!.push(item);
    }
  });

  return [...result].map(([k, value]) => ({
    key: k as KeyType,
    value: sortFn ? value.sort(sortFn) : value,
  }));
};

export const filterNilValues = <T>(array: (T | undefined | null)[]): T[] => {
  return array.filter((item) => item !== undefined && item !== null) as T[];
};

export const mapDateRange = (value: [Moment | undefined, Moment | undefined]) => {
  if (Array.isArray(value)) {
    const val0 = value[0]?.format?.(SHORT_DATE_FORMAT) ?? '?';
    const val1 = value[1]?.format?.(SHORT_DATE_FORMAT) ?? '?';

    return `${val0} - ${val1}`;
  }

  return undefined;
};

export const defaultNonTurnRehabJobTypeFilter = [
  JobType.MUNICIPAL_REPAIR,
  JobType.COMPLIANCE,
  JobType.BUYER_REPAIR,
  JobType.HOA_REPAIR,
  JobType.GENERAL_REMEDIATION,
  JobType.MOVE_IN,
  JobType.PARTIAL,
];

export const defaultTurnRehabJobTypeFilter = [JobType.REHAB, JobType.TURN];

export const buildPathForEntity = (
  entityType: EntityType | InvoiceAttributionEntityType | ProcessType,
  entityId: number,
): string | undefined => {
  switch (entityType) {
    case EntityType.PROPERTY:
      return `/properties/${entityId}`;
    case EntityType.WORK_ORDER:
    case InvoiceAttributionEntityType.WORK_ORDER:
      return `/maintenance/work-orders/${entityId}`;
    case EntityType.TICKET:
      return `/tickets/${entityId}`;
    case EntityType.PERSON:
      return `/contact?partyId=${entityId}`;
    case EntityType.JOB:
      return `/maintenance/jobs/${entityId}`;
    case EntityType.VENDOR:
      return `/maintenance/vendors/${entityId}`;
    case EntityType.BID:
      return `/maintenance/bids/${entityId}`;
    case EntityType.PARTY:
    case EntityType.ORGANIZATION:
      return `/contact?partyId=${entityId}`;
    case EntityType.UNIT:
      return `/units/${entityId}`;
    case EntityType.DISPUTE:
    case ProcessType.DISPUTE:
      return `/residents/disputes/${entityId}`;
    case EntityType.COLLECTION:
    case ProcessType.COLLECTION:
      return `/residents/collections`;
    case ProcessType.DISPOSITION:
      return `/properties/dispositions/${entityId}`;
    case ProcessType.DISPOSITION_OFFER:
      return `/properties/dispositions/offers/${entityId}`;
    case EntityType.LEASING:
    case ProcessType.LEASING:
      return `/prospects/leasing/${entityId}`;
    case EntityType.LISTING_PROCESS:
    case ProcessType.LISTING_PROCESS:
      return `/properties/listings/${entityId}`;
    case EntityType.MOVE_IN:
    case ProcessType.MOVE_IN:
      return `/residents/move-ins/${entityId}`;
    case EntityType.MOVE_OUT:
    case ProcessType.MOVE_OUT:
      return `/residents/move-outs/${entityId}`;
    case ProcessType.RENEWAL:
      return `/residents/renewals/${entityId}`;
    case EntityType.TURN_REHAB:
    case ProcessType.TURN_REHAB:
      return `/maintenance/turn-rehabs/${entityId}`;
    case ProcessType.UNIT_APPLICATION:
      return `/prospects/unit-applications/${entityId}`;
    case ProcessType.EVICTION:
      return `/residents/evictions/${entityId}`;
    case ProcessType.UNIT_ONBOARDING:
      return `/properties/onboardings/${entityId}`;
    default:
      return undefined;
  }
};

export const getProcessAndRelatedTasks = <TaskType extends Task>(
  tasks: TaskType[],
  processId: number,
  processType: ProcessType | ProcessType[],
) => {
  const processTasks: TaskType[] = [];
  const relatedTasks: TaskType[] = [];

  const isSameType = Array.isArray(processType)
    ? (type: ProcessType) => processType.includes(type)
    : (type: ProcessType) => processType === type;

  tasks.forEach((task) => {
    if (task.processId === processId && isSameType(task.processType)) {
      processTasks.push(task);
    } else {
      relatedTasks.push(task);
    }
  });

  return { processTasks, relatedTasks };
};

export const generateNumbersBetween = (start: number, end: number) =>
  Array(end - start + 1)
    .fill(1)
    .map((_, idx) => start + idx);

export const getPrimary = <R extends { primary?: boolean }>(emailList: R[]): R | undefined =>
  emailList.find((e) => e.primary) || emailList[0];

export const getLocationParams = (location: Location) => queryString.parse(location.search);

export const getFirst = <T = string>(
  objectOrArray: T | T[] | undefined | null,
): T | undefined | null => (objectOrArray instanceof Array ? objectOrArray[0] : objectOrArray);

/** @see https://skyboxcapital.atlassian.net/browse/FB-266 */
export const getFileUploadLimit = (concurrentUploads = 2) => pLimit(concurrentUploads);

export const isAutopayVisible = (lease: LeaseSearchResult | undefined): boolean =>
  !!lease &&
  (lease.status === LeaseStatus.PENDING || lease.status === LeaseStatus.ACTIVE) &&
  lease.resident?.status !== ResidentStatus.EVICTION &&
  lease.resident?.status !== ResidentStatus.PAST &&
  lease.resident?.status !== ResidentStatus.CANCELLED;

export const arrayToMap = <T extends object, K extends keyof any = keyof any, V = T>(
  arr: T[],
  getKey: (item: T) => K,
  getValue?: (item: T) => V,
): Partial<Record<K, V>> =>
  arr.reduce((map, item) => ({ ...map, [getKey(item)]: getValue ? getValue(item) : item }), {});

export const setItemInLocalStorageAndSendEvent = (key: string, newValue: string) => {
  const oldValue = localStorage.getItem(key);
  localStorage.setItem(key, newValue);
  window.dispatchEvent(
    new StorageEvent('storage', {
      key,
      oldValue,
      newValue,
      storageArea: localStorage,
      url: window.location.href,
    }),
  );
};
