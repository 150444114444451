import type { Company } from '@/domain/company';
import type { Office } from '@/domain/office';
import type { OpeningHours } from '@/domain/opening-hours';
import type { OpeningHoursOverride } from '@/domain/opening-hours-override';
import type { Effect, Model, Reducer } from '@/models/connect';
import { CompanyService } from '@/services/company';
import { OfficeService } from '@/services/office';
import { OpeningHoursService } from '@/services/opening-hours';
import { OpeningHoursOverrideService } from '@/services/opening-hours-override';
import message from 'antd/es/message';

type State = {
  list: Company[];
  company: Partial<Company>;
  offices: Office[];
  office: Partial<Office>;
  openingHours: OpeningHours[];
  openingHoursOverrides: OpeningHoursOverride[];
};

type Effects = {
  search: Effect;
  findById: Effect;
  create: Effect;
  searchOffices: Effect;
  createOffice: Effect;
  deleteOffice: Effect;
  updateOffice: Effect;
  getOpeningHours: Effect;
  getHolidays: Effect;
  updateOfficeHours: Effect;
  createOverride: Effect;
  deleteOverride: Effect;
};

type Reducers = {
  setList: Reducer<State>;
  setCompany: Reducer<State>;
  setOffices: Reducer<State>;
  setOffice: Reducer<State>;
  setOpeningHours: Reducer<State>;
  setHolidays: Reducer<State>;
};

const model: Model<State, Effects, Reducers> = {
  namespace: 'property-managers',

  state: {
    list: [],
    company: {},
    offices: [],
    office: {},
    openingHours: [],
    openingHoursOverrides: [],
  },

  effects: {
    *search({ payload }, { call, put }) {
      yield put({
        type: 'setList',
        payload: [],
      });

      const response = yield call(CompanyService.search, payload);

      yield put({
        type: 'setList',
        payload: response,
      });
    },
    *findById({ payload }, { call, put }) {
      yield put({
        type: 'setCompany',
        payload: {},
      });

      const response = yield call(CompanyService.findById, payload);

      yield put({
        type: 'setCompany',
        payload: response,
      });
    },
    *searchOffices({ payload }, { call, put }) {
      yield put({
        type: 'setOffices',
        payload: [],
      });

      const response = yield call(OfficeService.search, payload);

      yield put({
        type: 'setOffices',
        payload: response,
      });
    },
    *create({ payload }, { call, put, select }) {
      const response = yield call(CompanyService.create, payload);

      const companies = (yield select(
        (state: any) => state['property-managers'].list,
      )) as Company[];

      yield put({ type: 'setList', payload: [...companies, response] });
    },
    *createOffice({ payload }, { call, put, select }) {
      yield call(OfficeService.create, payload.companyId, payload.office);

      message.success('Office Created.');

      const company = (yield select((state: any) => state['property-managers'].company)) as Company;

      yield put({ type: 'searchOffices', payload: company.id });
    },
    *deleteOffice({ payload }, { call, put, select }) {
      yield call(OfficeService.delete, payload.companyId, payload.office);

      message.success('Office Deleted.');

      const company = (yield select((state: any) => state['property-managers'].company)) as Company;

      yield put({ type: 'searchOffices', payload: company.id });
    },
    *updateOffice({ payload, operations }, { call, put, select }) {
      const response = (yield call(
        OfficeService.patch,
        payload.companyId,
        payload.officeId,
        operations,
        payload.version,
      )) as Office;

      const offices = (yield select(
        (state: any) => state['property-managers'].offices,
      )) as Office[];
      const officeIndex = offices.findIndex((office) => office.id === response.id);

      if (officeIndex !== -1) {
        offices[officeIndex] = response;
      }

      yield put({
        type: 'setOffices',
        payload: [...offices],
      });
    },
    *getOpeningHours({ payload }, { call, put }) {
      const response = yield call(OpeningHoursService.search, payload);

      yield put({ type: 'setOpeningHours', payload: response });
    },
    *getHolidays({ payload }, { call, put }) {
      const response = yield call(OpeningHoursOverrideService.search, payload);

      yield put({ type: 'setHolidays', payload: response });
    },
    *updateOfficeHours({ payload }, { call, put, all }) {
      const openingHours = yield all(
        payload.map((openingHour: OpeningHours) => {
          const method = openingHour.id ? OpeningHoursService.update : OpeningHoursService.create;
          return call(method, openingHour);
        }),
      );

      yield put({
        type: 'setOpeningHours',
        payload: openingHours,
      });

      message.success('Open Hours Created.');
    },
    *createOverride({ payload }, { call, select, put }) {
      const holiday = (yield call(
        OpeningHoursOverrideService.create,
        payload,
      )) as OpeningHoursOverride;

      message.success('Holiday Created.');

      const holidays = (yield select(
        (state: any) => state['property-managers'].openingHoursOverrides,
      )) as OpeningHoursOverride[];

      holidays.push(holiday);

      yield put({ type: 'setHolidays', payload: [...holidays] });
    },
    *deleteOverride({ payload }, { call, put, select }) {
      yield call(OpeningHoursOverrideService.delete, payload);

      let holidays = (yield select(
        (state: any) => state['property-managers'].openingHoursOverrides,
      )) as OpeningHoursOverride[];

      holidays = holidays.filter((holiday) => holiday.id !== payload.id);

      yield put({ type: 'setHolidays', payload: [...holidays] });

      message.success('Holiday Deleted.');
    },
  },

  reducers: {
    setList(state, action) {
      return {
        ...state,
        list: action.payload,
      };
    },
    setCompany(state, action) {
      return {
        ...state,
        company: action.payload,
      };
    },
    setOffices(state, action) {
      return {
        ...state,
        offices: action.payload,
      };
    },
    setOffice(state, action) {
      return {
        ...state,
        office: action.payload,
      };
    },
    setOpeningHours(state, action) {
      return {
        ...state,
        openingHours: action.payload,
      };
    },
    setHolidays(state, action) {
      return {
        ...state,
        openingHoursOverrides: action.payload,
      };
    },
  },
};

export default model;
